import React from "react";
import ReactSlider from "react-slider";
import styled from "styled-components";
import tw from "twin.macro";

const StyledSlider = styled(ReactSlider)`
  ${tw`w-full h-1`}
  line-height: 0.25rem;
`;

const StyledThumb = styled.div`
  height: 1rem;
  line-height: 1rem;
  width: 1rem;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: grab;
  top: -0.375rem;
`;

const StyledThumbLabel = styled.div`
  ${tw`absolute text-sm text-white w-full`}
  top: 1rem;
`;

// TODO figure out types
const Thumb = (props: any, state: any) => {
  return (
    <StyledThumb {...props}>
      <StyledThumbLabel>{state.valueNow}</StyledThumbLabel>
    </StyledThumb>
  );
};

// TODO figure out types
const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props: any) => (props.index === 1 ? "#1db954" : "#535353")};
  border-radius: 999px;
`;

// TODO figure out types
const Track = (props: any, state: any) => {
  return <StyledTrack {...props} index={state.index} />;
};

// const SliderLabel = styled.label`
//   ${tw`text-xl text-white pb-1`}
// `;

type Props = {
  attribute: string;
  defaultValue?: number[];
  onAfterChange: (values: number | number[] | null | undefined) => void;
};

export const AttributeSlider = ({
  attribute,
  onAfterChange,
  defaultValue,
}: Props) => {
  return (
    <>
      {/* <SliderLabel htmlFor="attribute">{attribute}</SliderLabel> */}
      <StyledSlider
        defaultValue={defaultValue ? defaultValue : [0, 100]}
        ariaLabel={["Lower thumb", "Upper thumb"]}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        renderThumb={Thumb}
        renderTrack={Track}
        pearling
        minDistance={0}
        onAfterChange={onAfterChange}
      />
    </>
  );
};
