import React, { useEffect } from "react";
import { useRadioState, Radio, RadioGroup } from "reakit/Radio";
import styled, { css } from "styled-components";
import tw from "twin.macro";

const StyledRadio = styled(Radio)`
  ${tw`hidden`}
`;

type RadioProps = {
  checked: boolean;
};

const StyledRadioLabel = styled.label<RadioProps>`
  ${tw`rounded-full md:rounded-none md:first:rounded-l-full md:last:rounded-r-full`};
  ${tw`text-white checked:text-black`};
  ${tw`p-5 cursor-pointer`};

  ${(props) =>
    props.checked &&
    css`
      ${tw`bg-green-500 hover:bg-green-600`};
    `}

  ${(props) =>
    !props.checked &&
    css`
      ${tw`bg-black hover:bg-gray-600 active:bg-gray-900 focus:bg-gray-700 `};
      ${tw`border-white border-solid border-2 md:border-0`}
    `}
`;

type Props = {
  options: { value: string; label: string }[];
  onValueChange: (value: string) => void;
  defaultValue: string;
};

export const CustomRadio = ({
  options,
  onValueChange,
  defaultValue,
}: Props) => {
  const startState = defaultValue ? defaultValue : options[0].value;
  const radio = useRadioState({ state: startState });

  useEffect(() => {
    onValueChange(radio.state ? (radio.state as string) : "");
  }, [radio.state, onValueChange]);

  return (
    <RadioGroup
      {...radio}
      className="flex flex-col md:flex-row justify-center space-y-1 md:space-y-0 "
    >
      {options.map((op) => (
        <StyledRadioLabel key={op.value} checked={radio.state === op.value}>
          <StyledRadio {...radio} type="radio" id={op.value} value={op.value} />
          {op.label}
        </StyledRadioLabel>
      ))}
    </RadioGroup>
  );
};
