import React from "react";
import { SpotifyButton } from "../../components/spotify-button";
import styled from "styled-components";
import tw from "twin.macro";

const GenerateStateString = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const LandingSection = styled.div`
  ${tw`flex flex-col justify-center items-center h-screen bg-gradient-to-br from-green-300 to-blue-400`};

  > button {
    ${tw`max-w-xl`};
    padding: 20px;
  }
`;

const HeroText = styled.h1`
  color: ${(props) => props.theme.colors.black};
  ${tw`font-bold text-5xl`}
`;

const HeroSubText = styled.h3`
  color: ${(props) => props.theme.colors.darkGrey};
  ${tw`font-semibold text-xl mb-16`}
`;

const LoginPage = () => {
  const cliendId = process.env.REACT_APP_CLIENT_ID;
  const redirect_uri = process.env.REACT_APP_CALLBACL_URI;

  const onClickLogin = () => {
    const state = GenerateStateString(10);
    try {
      window.localStorage.setItem("auth-state", state);
    } catch (e) {
      console.log(e);
    }
    //debugger;
    const roles =
      "playlist-modify-private%20user-read-playback-state%20user-modify-playback-state%20user-top-read";
    const authUrl = `https://accounts.spotify.com/authorize?client_id=${cliendId}&response_type=token&redirect_uri=${redirect_uri}&state=${state}&scope=${roles}&show_dialog=true`;
    window.location.href = authUrl;
  };

  return (
    <>
      <LandingSection>
        <HeroText>Mood Mixer</HeroText>
        <HeroSubText>A mix for every mood</HeroSubText>
        <SpotifyButton onClick={onClickLogin}>Start with Spotify</SpotifyButton>
      </LandingSection>
    </>
  );
};

export default LoginPage;
