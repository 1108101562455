import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import SeedContext from "./context/seedContext";
import SpotifyContext from "./context/spotifyContext";
import { ThemeProvider } from "styled-components";
import { theme, Style } from "./utils/styles";

import "./assets/styles.css";
import "./assets/fonts.css";

ReactDOM.render(
  <SpotifyContext>
    <SeedContext>
      <ThemeProvider theme={theme}>
        <>
          <Style />
          <App />
        </>
      </ThemeProvider>
    </SeedContext>
  </SpotifyContext>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
