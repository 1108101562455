import React from "react";
import { SpotifyButton } from "../../components/spotify-button";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

import {
  SeedActionTypes,
  SeedContext,
  SeedSourceOptions,
} from "../../context/seedContext";
import { AttributeSlider } from "../../components/attribute-slider";
import { SeedSetup } from "../../components/seed-setup";
import { InputLabel } from "../../components/input-label";

const SeedSettings = styled.div`
  ${tw`flex flex-col items-center w-full px-4 mb-10`}

  > div {
    ${tw`w-full md:w-1/2 lg:w-3/5`}
  }
  > :nth-child(even) {
    ${tw`mb-4`}
  }
`;

const StyledHeader = styled.header`
  ${tw`w-full text-lg`};
  height: ${(props) => props.theme.headerHeight};
  background-color: ${(props) => props.theme.colors.black};
  color: #fff;
  padding: 10px;
`;

const StyledSlider = styled.div`
  ${tw`mb-4`}
`;

const SeedPage = () => {
  const history = useHistory();
  const { state, dispatch } = React.useContext(SeedContext);

  return (
    <>
      <StyledHeader>Set the mood</StyledHeader>

      <SeedSettings>
        <InputLabel
          tooltipText={
            "Listening period on which the mix is based. You can also choose custom artists."
          }
        >
          Seed
        </InputLabel>
        <SeedSetup></SeedSetup>

        <StyledSlider>
          <InputLabel
            tooltipText={
              "How suitable a track is for dancing based on a combination of musical elements including tempo, rhythm stability, beat strength, and overall regularity."
            }
          >
            Danceability
          </InputLabel>
          <AttributeSlider
            attribute={"Danceability"}
            defaultValue={[state.minDanceability, state.maxDanceability]}
            onAfterChange={(values) =>
              dispatch({
                type: SeedActionTypes.SET_DANCEABILITY,
                payload: values as number[],
              })
            }
          ></AttributeSlider>
        </StyledSlider>

        <StyledSlider>
          <InputLabel
            tooltipText={
              "Perceptual measure of intensity and activity. Typically, energetic tracks feel fast, loud, and noisy."
            }
          >
            Energy
          </InputLabel>
          <AttributeSlider
            attribute={"Energy"}
            defaultValue={[state.minEnergy, state.maxEnergy]}
            onAfterChange={(values) =>
              dispatch({
                type: SeedActionTypes.SET_ENERGY,
                payload: values as number[],
              })
            }
          ></AttributeSlider>
        </StyledSlider>
        <StyledSlider>
          <InputLabel
            tooltipText={
              "Describes the musical positiveness conveyed by a track. Tracks with high valence sound more positive (e.g. happy, cheerful, euphoric), while tracks with low valence sound more negative (e.g. sad, depressed, angry)."
            }
          >
            Valence
          </InputLabel>
          <AttributeSlider
            attribute={"Valence"}
            defaultValue={[state.minValence, state.maxValence]}
            onAfterChange={(values) =>
              dispatch({
                type: SeedActionTypes.SET_VALENCE,
                payload: values as number[],
              })
            }
          ></AttributeSlider>
        </StyledSlider>

        <StyledSlider>
          <InputLabel tooltipText={"How popular a track is."}>
            Popularity
          </InputLabel>
          <AttributeSlider
            attribute={"Popularity"}
            defaultValue={[state.minPopularity, state.maxPopularity]}
            onAfterChange={(values) =>
              dispatch({
                type: SeedActionTypes.SET_POPULARITY,
                payload: values as number[],
              })
            }
          ></AttributeSlider>
        </StyledSlider>
      </SeedSettings>
      <SpotifyButton
        onClick={() => history.push("/mix")}
        disabled={
          state.artists.length === 0 &&
          state.seed.source === SeedSourceOptions.CUSTOM
        }
      >
        Make Mix
      </SpotifyButton>
    </>
  );
};

export default SeedPage;
