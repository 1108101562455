import styled from "styled-components";

export const SpotifyButton = styled.button<{
  secondary?: boolean;
  small?: boolean;
}>`
  color: #e8e6e3;
  background-color: ${(props) =>
    props.secondary
      ? props.theme.colors.black
      : props.disabled
      ? props.theme.colors.darkGrey
      : props.theme.colors.green};

  line-height: 1;
  border-radius: 500px;

  padding: ${(props) => (props.small ? "11px 32px 9px" : "21px 56px 19px")};
  font-size: ${(props) => (props.small ? "12px" : "16px")};

  transition-property: background-color, border-color, color, box-shadow, filter,
    -webkit-box-shadow, -webkit-filter;
  transition-duration: 0.3s;

  letter-spacing: 2px;
  min-width: 160px;
  text-transform: uppercase;
  white-space: normal;
  font-weight: ${(props) => (props.small ? 500 : 700)};
  text-align: center;
  vertical-align: middle;

  border-width: ${(props) => (props.secondary ? "2px" : "1px")};
  border-style: solid;
  border-color: ${(props) =>
    props.secondary
      ? props.theme.colors.white
      : props.disabled
      ? props.theme.colors.darkGrey
      : "transparent"};

  :hover {
    background-color: ${(props) =>
      props.secondary
        ? props.theme.colors.darkGrey
        : props.disabled
        ? props.theme.colors.darkGrey
        : props.theme.colors.lightGreen};
  }

  :focus {
    background-color: ${(props) =>
      props.secondary
        ? props.theme.colors.darkGrey
        : props.disabled
        ? props.theme.colors.darkGrey
        : props.theme.colors.darkGreen};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 2px inset;
  }

  :active {
    background-color: ${(props) =>
      props.secondary
        ? props.theme.colors.black
        : props.disabled
        ? props.theme.colors.darkGrey
        : props.theme.colors.darkGreen};
    box-shadow: none;
    color: ${(props) =>
      props.secondary
        ? props.theme.colors.lightWhite
        : props.disabled
        ? "#e8e6e3"
        : "rgb(23, 228, 133)"};
    outline-color: currentcolor;
  }
`;
