import React, { useRef } from "react";
import AsyncSelect from "react-select/async";
import { SpotifyContext } from "../../context/spotifyContext";
import { SeedActionTypes, SeedContext } from "../../context/seedContext";
import { useHistory } from "react-router-dom";
import { ActionMeta, Styles, ValueType } from "react-select";

export const SpotifySearchBar = () => {
  const { spotifyApi } = React.useContext(SpotifyContext);
  const { state, dispatch } = React.useContext(SeedContext);
  const select = useRef(null);
  const history = useHistory();

  const selectStyles: Partial<Styles> = {
    dropdownIndicator: (provided, state) => ({
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
  };

  const mapArtistItemsToOption = (items: SpotifyApi.ArtistObjectFull[]) =>
    items.map((artist) => ({
      value: artist.id,
      label: artist.name,
      artist: artist,
    }));

  const artistOptions = (inputValue: string) => {
    return new Promise((resolve) => {
      if (state.canAdd) {
        spotifyApi.searchArtists(inputValue, { limit: 10 }).then(
          (artists) => {
            resolve(mapArtistItemsToOption(artists.artists.items));
          },
          (err) => {
            if (err.status === 401) {
              history.push("/");
            } else {
              resolve([]);
            }
          }
        );
      } else {
        resolve([]);
      }
    });
  };

  const onChange = (
    artists: ValueType<{
      value: string;
      label: string;
      artist: SpotifyApi.ArtistObjectFull;
    }>,
    action: ActionMeta<{
      value: string;
      label: string;
      artist: SpotifyApi.ArtistObjectFull;
    }>
  ) => {
    switch (action.action) {
      case "select-option":
        if (action.option) {
          dispatch({
            type: SeedActionTypes.ADD_ARTIST,
            payload: action.option.artist,
          });
        }
        break;
      case "pop-value":
      case "remove-value":
        if (action.removedValue) {
          dispatch({
            type: SeedActionTypes.REMOVE_ARTIST,
            payload: action.removedValue.artist.id,
          });
        }
        break;
      default:
        console.log(`Unexpected action ${action.action}`);
    }
  };

  return (
    <AsyncSelect
      ref={select}
      isMulti
      cacheOptions
      styles={selectStyles}
      onChange={(elements, action) => onChange(elements, action)}
      openMenuOnClick={false}
      loadOptions={artistOptions}
      value={mapArtistItemsToOption(state.artists)}
      noOptionsMessage={() =>
        state.canAdd ? "No results found." : "Limited to 5 options."
      }
      placeholder="Select at least one artist..."
    />
  );
};
