import React, { useEffect } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SeedPage from "./pages/seed";
import LoginPage from "./pages/login";
import MixPage from "./pages/mix";
import CallbackPage from "./pages/callback";
import { PrivateRoute } from "./utils/private-route";

const App = () => {
  useEffect(() => {
    (function(w){
      var js, fjs = document.getElementsByTagName('script')[0];
      if (document.getElementById("mkid-script")) {return;}
      js = document.createElement('script'); js.id = "mkid-script";
      js.async = true; js.defer = true;
      js.onload = function(){
          (window as any).makerIdInitialize({         
          id: w                
      })};
      js.src = "https://makerid.page/embed/embed.js";
      fjs.parentNode!.insertBefore(js, fjs)
    }('kCXFGKmPgcCXtpgYMt6F'));
  }, [])

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/mix" component={MixPage} />
        <PrivateRoute path="/setup" component={SeedPage} />
        <Route path="/callback">
          <CallbackPage />
        </Route>
        <Route path="/">
          <LoginPage />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
