import React from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useTooltipState, Tooltip, TooltipReference } from "reakit/Tooltip";
import { Button } from "reakit/Button";
import styled from "styled-components";
import tw from "twin.macro";

const StyledLabel = styled.label`
  ${tw`text-xl text-white text-center`}
`;

const StyledTooltip = styled(Tooltip)`
  ${tw`bg-gray-700 text-white rounded-lg max-w-xs p-2 z-10`}
`;

const StyledDiv = styled.div`
  ${tw`space-x-2 h-full`}
`;

type Props = {
  tooltipText: string;
  children: React.ReactNode;
};

export const InputLabel = ({ tooltipText, children }: Props) => {
  const tooltipState = useTooltipState();

  return (
    <>
      <StyledDiv>
        <StyledLabel>{children}</StyledLabel>
        <TooltipReference
          className="absolute"
          style={{ transform: "translateY(6px)" }}
          {...tooltipState}
          as={Button}
        >
          <IoMdInformationCircleOutline size={18} color="white" className="" />
        </TooltipReference>
      </StyledDiv>

      <StyledTooltip {...tooltipState}>{tooltipText}</StyledTooltip>
    </>
  );
};
