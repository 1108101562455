import React from "react";
import { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { SpotifyContext } from "../context/spotifyContext";

export const PrivateRoute: React.FC<RouteProps> = ({
  component,
  path,
  exact,
  ...routeProps
}) => {
  const { loggedIn } = useContext(SpotifyContext);
  return loggedIn ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to="/" />
  );
};
