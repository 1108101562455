import React, { createContext, useState, useEffect, useRef } from "react";
import SpotifyWebApi from "spotify-web-api-js";

export type SpotifyState = {
  spotifyApi: SpotifyWebApi.SpotifyWebApiJs;
  setAccessToken: React.Dispatch<React.SetStateAction<Auth>>;
  loggedIn: boolean;
};

const defaultStore: SpotifyState = {
  spotifyApi: new SpotifyWebApi(),
  setAccessToken: () => null,
  loggedIn: false,
};

export const SpotifyContext = createContext<SpotifyState>(defaultStore);

type Props = {
  children: React.ReactNode;
};

export class Auth {
  accessToken: string = "";
  expiration: Date = new Date(0);

  isValid(): boolean {
    return this.expiration.valueOf() > Date.now();
  }

  constructor(obj: Partial<Auth>) {
    Object.assign(this, obj);
    if (obj.expiration) {
      this.expiration = new Date(obj.expiration);
    } else {
      this.expiration = new Date(0);
    }
  }
}

const getSavedAuth: () => Auth = () => {
  const savedString = localStorage.getItem("moodmixer-auth");

  if (savedString == null) {
    return new Auth({ accessToken: "", expiration: new Date(0) });
  }

  return new Auth(JSON.parse(savedString));
};

const SpotifyProvider: React.FC<Props> = ({ children }: Props) => {
  const defaultAccessToken = getSavedAuth();
  const spotifyClient = new SpotifyWebApi();

  if (defaultAccessToken.isValid()) {
    spotifyClient.setAccessToken(defaultAccessToken.accessToken);
  }

  const spotify = useRef(spotifyClient);
  const [accessToken, setAccessToken] = useState(defaultAccessToken);
  const [loggedIn, setLoggedIn] = useState(defaultAccessToken.isValid());

  useEffect(() => {
    localStorage.setItem("moodmixer-auth", JSON.stringify(accessToken));
    setLoggedIn(accessToken.isValid());

    if (accessToken.isValid()) {
      spotify.current.setAccessToken(accessToken.accessToken);
    }
  }, [accessToken]);

  const store: SpotifyState = {
    spotifyApi: spotify.current,
    setAccessToken,
    loggedIn,
  };

  return (
    <SpotifyContext.Provider value={store}>{children}</SpotifyContext.Provider>
  );
};

export default SpotifyProvider;
