import React, { createContext, useReducer } from "react";
import { ActionMap } from "./reducers";

export enum SeedSourceOptions {
  LAST_MONTH = "short_term",
  LAST_6_MONTHS = "medium_term",
  ALL_TIME = "long_term",
  CUSTOM = "custom",
}

export enum SeedActionTypes {
  SET_SEED_SOURCE = "setSeedSource",
  ADD_ARTIST = "addArtist",
  REMOVE_ARTIST = "removeArtist",
  SET_DANCEABILITY = "setDanceability",
  SET_ENERGY = "setEnergy",
  SET_VALENCE = "setValence",
  SET_POPULARITY = "setPopularity",
}

type Artist = {
  id: string;
  name: string;
  uri: string;
};

type Seed = {
  source: string;
};

type SeedState = {
  seed: Seed;
  artists: SpotifyApi.ArtistObjectFull[];
  canAdd: boolean;
  minDanceability: number;
  maxDanceability: number;
  minEnergy: number;
  maxEnergy: number;
  minValence: number;
  maxValence: number;
  minPopularity: number;
  maxPopularity: number;
};

type SeedActionPayload = {
  [SeedActionTypes.SET_SEED_SOURCE]: SeedSourceOptions;
  [SeedActionTypes.ADD_ARTIST]: SpotifyApi.ArtistObjectFull;
  [SeedActionTypes.REMOVE_ARTIST]: string;
  [SeedActionTypes.SET_DANCEABILITY]: number[];
  [SeedActionTypes.SET_ENERGY]: number[];
  [SeedActionTypes.SET_VALENCE]: number[];
  [SeedActionTypes.SET_POPULARITY]: number[];
};

export type SeedActions = ActionMap<SeedActionPayload>[keyof ActionMap<
  SeedActionPayload
>];

const defaultState: SeedState = {
  seed: {
    source: SeedSourceOptions.LAST_MONTH,
  },
  artists: [],
  canAdd: true,
  minDanceability: 0,
  maxDanceability: 100,
  minEnergy: 0,
  maxEnergy: 100,
  minValence: 0,
  maxValence: 100,
  minPopularity: 0,
  maxPopularity: 100,
};

export const SeedContext = createContext<{
  state: SeedState;
  dispatch: React.Dispatch<SeedActions>;
}>({ state: defaultState, dispatch: () => null });

const ArtistLimit = 5;

function reducer(state: SeedState, action: SeedActions): SeedState {
  switch (action.type) {
    case SeedActionTypes.SET_SEED_SOURCE:
      return {
        ...state,
        seed: {
          source: action.payload,
        },
      };
    case SeedActionTypes.ADD_ARTIST:
      if (state.artists.length < ArtistLimit) {
        const newArtists = [...state.artists, action.payload];
        return {
          ...state,
          artists: newArtists,
          canAdd: newArtists.length < ArtistLimit,
        };
      } else {
        return state;
      }

    case SeedActionTypes.REMOVE_ARTIST:
      const newArtists = state.artists.filter((a) => a.id !== action.payload);
      return {
        ...state,
        artists: newArtists,
        canAdd: newArtists.length < ArtistLimit,
      };

    case SeedActionTypes.SET_DANCEABILITY:
      return {
        ...state,
        minDanceability: action.payload[0],
        maxDanceability: action.payload[1],
      };
    case SeedActionTypes.SET_ENERGY:
      return {
        ...state,
        minEnergy: action.payload[0],
        maxEnergy: action.payload[1],
      };
    case SeedActionTypes.SET_VALENCE:
      return {
        ...state,
        minValence: action.payload[0],
        maxValence: action.payload[1],
      };
    case SeedActionTypes.SET_POPULARITY:
      return {
        ...state,
        minPopularity: action.payload[0],
        maxPopularity: action.payload[1],
      };
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
};

const SeedProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <SeedContext.Provider value={{ state, dispatch }}>
      {children}
    </SeedContext.Provider>
  );
};

export default SeedProvider;
