import React, { useCallback } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { SpotifySearchBar } from "../search";
import {
  SeedActionTypes,
  SeedContext,
  SeedSourceOptions,
} from "../../context/seedContext";
import { CustomRadio } from "../radio";

const SearchBarLabel = styled.div`
  ${tw`text-xl text-white text-center`}
`;

export const SeedSetup = () => {
  const { state, dispatch } = React.useContext(SeedContext);

  const handleSourceChange = useCallback(
    (val) => {
      dispatch({ type: SeedActionTypes.SET_SEED_SOURCE, payload: val });
    },
    [dispatch]
  );

  return (
    <>
      <CustomRadio
        options={[
          { label: "Last Month", value: SeedSourceOptions.LAST_MONTH },
          { label: "Last 6 Months", value: SeedSourceOptions.LAST_6_MONTHS },
          { label: "All Time", value: SeedSourceOptions.ALL_TIME },
          { label: "Custom", value: SeedSourceOptions.CUSTOM },
        ]}
        onValueChange={handleSourceChange}
        defaultValue={state.seed.source}
      ></CustomRadio>

      {state.seed.source === SeedSourceOptions.CUSTOM && (
        <>
          <SearchBarLabel>Artists</SearchBarLabel>
          <SpotifySearchBar></SpotifySearchBar>
        </>
      )}
    </>
  );
};
