import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SpotifyContext, Auth } from "../../context/spotifyContext";

type GrantedAccessParams = {
  access_token: string;
  expires_in: number;
  state: string;
  token_type: string;
  success: "true";
};

type DeniedAccessParams = {
  error: string;
  state: string;
  success: "false";
};

type SpotifyCallbackParams = GrantedAccessParams | DeniedAccessParams;

const CallbackPage = () => {
  const history = useHistory();
  const { hash } = useLocation();

  const { setAccessToken, loggedIn } = React.useContext(SpotifyContext);

  useEffect(() => {
    //debugger;
    const storedState = localStorage.getItem("auth-state");
    localStorage.removeItem("auth-state");

    const paramString = hash.substring(1);

    let params: SpotifyCallbackParams;
    try {
      params = JSON.parse(
        '{"' + paramString.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      params.success = "true";
    } catch (e) {
      params = { error: "failed to parse params", state: "", success: "false" };
    }

    if (params.state === storedState && params.success === "true") {
      const auth = new Auth({
        accessToken: params.access_token,
        expiration: new Date(Date.now() + params.expires_in * 1000),
      });

      setAccessToken(auth);
    } else {
      history.push("/");
    }
  }, [hash, history, setAccessToken]);

  useEffect(() => {
    if (loggedIn) {
      history.push("/setup");
    }
  }, [loggedIn, history]);

  return <></>;
};

export default CallbackPage;
