import React, { useEffect, useState, useCallback } from "react";
import { SpotifyContext } from "../../context/spotifyContext";
import { SeedContext, SeedSourceOptions } from "../../context/seedContext";
import { useHistory } from "react-router-dom";
import { TrackCard } from "../../components/track-card";
import { SpotifyButton } from "../../components/spotify-button";
import { StickyScroll } from "../../components/sticky-scroll";
import styled from "styled-components";
import tw from "twin.macro";
import { Header } from "../../components/header";

const MixHeader = styled.div`
  ${tw`text-black text-4xl font-bold flex flex-col justify-center w-full items-center bg-gradient-to-br from-green-300 to-blue-400`};
  height: calc(40vh + ${(props) => props.theme.headerHeight});

  -webkit-box-shadow: inset 0px -130px 50px -50px rgba(18, 18, 18, 1);
  -moz-box-shadow: inset 0px -130px 50px -50px rgba(18, 18, 18, 1);
  box-shadow: inset 0px -130px 50px -50px rgba(18, 18, 18, 1);

  > button {
    max-width: 15rem;
    ${tw`mt-6`}
  }
`;

const ButtonBackground = styled.div`
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.black} 50%,
    rgba(0, 0, 0, 0) 50%
  );
`;

const MixList = styled.div`
  ${tw`flex flex-col items-center w-full max-w-full`};
  > div {
    ${tw`w-full lg:w-2/3`};
  }
`;

const MixCover = styled.div`
  ${tw`h-10 mt-3 mb-5`}
`;

const MixSubtitle = styled.div`
  ${tw`text-xs pb-4 text-gray-800 font-semibold`}
`;

const MixPage = () => {
  const { spotifyApi } = React.useContext(SpotifyContext);
  const { state } = React.useContext(SeedContext);

  const [mix, setMix] = useState<SpotifyApi.TrackObjectSimplified[]>([]);

  const [loadingMix, setLoadingMix] = useState(true);
  const [needMix, setNeedMix] = useState(true);

  const [
    player,
    setPlayer,
  ] = useState<SpotifyApi.CurrentPlaybackResponse | null>(null);

  const [seedArtists, setSeedArtists] = useState<SpotifyApi.ArtistObjectFull[]>(
    []
  );

  const history = useHistory();

  const getSeedArtists = useCallback<() => Promise<string[]>>(() => {
    return new Promise((resolve, reject) => {
      if (state.seed.source === SeedSourceOptions.CUSTOM) {
        setSeedArtists(state.artists);
        resolve(state.artists.map((a) => a.id));
      } else {
        spotifyApi
          .getMyTopArtists({ limit: 5, time_range: state.seed.source })
          .then(
            (values) => {
              setSeedArtists(values.items);
              resolve(values.items.map((artist) => artist.id));
            },
            (err) => {
              reject(err);
            }
          );
      }
    });
  }, [state, spotifyApi]);

  useEffect(() => {
    let isCancelled = false;
    spotifyApi.getMyCurrentPlaybackState().then(
      (playback) => {
        if (playback && !isCancelled) {
          setPlayer(playback);
        }
      },
      (err) => {
        if (err.status === 401) {
          history.push("/");
        }
      }
    );

    return () => {
      // TODO figure out if you can abort the SpotifyWebApi another way
      isCancelled = true;
    };
  }, [spotifyApi, history]);

  useEffect(() => {
    if (
      state.artists.length === 0 &&
      state.seed.source === SeedSourceOptions.CUSTOM
    ) {
      history.push("/setup");
    } else if (needMix) {
      setLoadingMix(true);
      getSeedArtists().then((artistIds) => {
        spotifyApi
          .getRecommendations({
            seed_artists: artistIds,
            min_energy: state.minEnergy / 100,
            max_energy: state.maxEnergy / 100,
            min_danceability: state.minDanceability / 100,
            max_danceability: state.maxDanceability / 100,
            min_valence: state.minValence / 100,
            max_valence: state.maxValence / 100,
            min_popularity: state.minPopularity,
            max_popularity: state.maxPopularity,
            limit: 50,
          })
          .then(
            (recs) => {
              setMix(recs.tracks);
              setLoadingMix(false);
              setNeedMix(false);
            },
            (err) => {
              if (err.status === 401) {
                history.push("/");
              } else {
                console.log(err);
                setMix([]);
                setLoadingMix(false);
              }
            }
          );
      });
    }
  }, [history, state, spotifyApi, needMix, getSeedArtists]);

  const createMixPlaylist = () => {
    spotifyApi.getMe().then(
      (me) => {
        spotifyApi
          .createPlaylist(me.id, {
            name: "Your mood mix",
            public: false,
            description: MixDescription(seedArtists),
          })
          .then(
            (playlist) => {
              spotifyApi.addTracksToPlaylist(
                playlist.id,
                mix.map((t) => t.uri)
              );
            },
            (err) => {
              throw err;
            }
          );
      },
      (err) => {
        if (err.status === 401) {
          history.push("/");
        } else {
          console.log("Failed to save playlist");
        }
      }
    );
  };

  const playMix = () => {
    if (spotifyApi && player) {
      spotifyApi.play({ uris: mix.map((t) => t.uri) }).then(
        () => {
          console.log("Playing");
          // TODO show message
        },
        (err) => {
          if (err.status === 401) {
            history.push("/");
          } else {
            console.log(err);
          }
          // TODO show error message and hide button
        }
      );
    }
  };

  const MixDescription = (artists: SpotifyApi.ArtistObjectFull[]) => {
    if (artists.length > 0) {
      let description = "Based on ";

      for (let i = 0; i < artists.length - 1; i++) {
        description += `${artists[i].name}, `;
      }

      if (artists.length > 1) {
        description += "and ";
      }

      description += `${artists[artists.length - 1].name}.`;

      return description;
    } else {
      return "";
    }
  };

  return (
    <>
      <Header
        title={"Your mood mix"}
        revealTitleHeight={40}
        onClickRefresh={() => setNeedMix(true)}
        onClickBack={() => history.push("/setup")}
      />
      <MixHeader>
        {!loadingMix && <MixCover>Your mood mix</MixCover>}
        {loadingMix && <MixCover>Mixing for your mood...</MixCover>}
        {!loadingMix && (
          <MixSubtitle>{MixDescription(seedArtists)}</MixSubtitle>
        )}
        {mix.length > 0 && !loadingMix && (
          <SpotifyButton secondary small onClick={createMixPlaylist}>
            Save as playlist
          </SpotifyButton>
        )}
      </MixHeader>
      {player && !loadingMix && (
        <StickyScroll stickToHeader>
          <ButtonBackground>
            <SpotifyButton onClick={playMix}>Play Now</SpotifyButton>
          </ButtonBackground>
        </StickyScroll>
      )}
      {!loadingMix && (
        <MixList>
          {mix.map((track) => (
            <TrackCard key={track.id} track={track}></TrackCard>
          ))}
        </MixList>
      )}
    </>
  );
};

export default MixPage;
