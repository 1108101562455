import { createGlobalStyle } from "styled-components";

export type Theme = {
  colors: {
    lightWhite: "#fff";
    white: "#F1F2EB";
    darkGrey: "#282828";
    grey: "#e8e6e3";
    black: "#121212";
    darkGreen: "#15823B";
    green: "#179443";
    lightGreen: "#1db954";
  };
  headerHeight: "3rem";
  font: "Montserrat, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue' ";
};

export const theme: Theme = {
  colors: {
    lightWhite: "#fff",
    white: "#F1F2EB",
    darkGrey: "#282828",
    grey: "#e8e6e3",
    black: "#121212",
    darkGreen: "#15823B",
    green: "#179443",
    lightGreen: "#1db954",
  },
  headerHeight: "3rem",
  font:
    "Montserrat, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue' ",
};

export const Style = createGlobalStyle`
${({ theme }: { theme: Theme }) => `
    body {
        text-align: center;
        margin: 0;
				font-family: ${theme.font};
				background-color: ${theme.colors.black};
    }
    `}
`;
