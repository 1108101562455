import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { IoMdArrowBack, IoMdRefresh } from "react-icons/io";
import tw from "twin.macro";

const StyledHeader = styled.header`
  ${tw`top-0 z-20 grid grid-rows-1 grid-cols-5 fixed w-full`};
  height: ${(props) => props.theme.headerHeight};
  background-color: ${(props) => props.theme.colors.black};
  padding: 10px;
`;

const BackButton = styled.button`
  ${tw`justify-self-start `};
  background-color: none;
  float: left;
`;

const HeaderTitle = styled.h1`
  ${tw`text-lg justify-self-center col-span-3`};
  opacity: 0;
  color: ${({ theme }) => theme.colors.white};
`;

const RefreshButton = styled.button`
  ${tw`justify-self-end`};
  background-color: none;
  float: right;
`;

type Props = {
  title: string;
  revealTitleHeight: number;
  onClickBack: () => void;
  onClickRefresh: () => void;
};

export const Header = ({
  title,
  revealTitleHeight,
  onClickBack,
  onClickRefresh,
}: Props) => {
  const header = useRef<HTMLDivElement>(null);
  const headerTitle = useRef<HTMLHeadingElement>(null);

  // Fade title
  useEffect(() => {
    if (header.current) {
      header.current.style.background = "0";
    }

    var onScrollCallback = (event: Event) => {
      const revealHeightPx = (window.innerHeight * revealTitleHeight) / 100;
      const currentScroll = window.pageYOffset;
      let opacity;
      if (currentScroll <= revealHeightPx) {
        opacity = 0 + currentScroll / revealHeightPx;
        if (header.current) {
          header.current.style.background = "0";
        }
      } else {
        opacity = 1;
        if (header.current) {
          header.current.style.removeProperty("background");
        }
      }
      if (headerTitle.current) {
        headerTitle.current.style.opacity = opacity.toString();
      }
    };

    window.addEventListener("scroll", onScrollCallback);

    return () => {
      window.removeEventListener("scroll", onScrollCallback);
    };
  }, [revealTitleHeight]);

  return (
    <>
      <StyledHeader ref={header}>
        <BackButton onClick={onClickBack}>
          <IoMdArrowBack size={28} color="#fff" />
        </BackButton>
        <HeaderTitle ref={headerTitle}>{title}</HeaderTitle>
        <RefreshButton onClick={onClickRefresh}>
          <IoMdRefresh size={28} color="#fff" />
        </RefreshButton>
      </StyledHeader>
    </>
  );
};
