import styled from "styled-components";
import tw from "twin.macro";

export const StickyScroll = styled.div<{ stickToHeader: boolean }>`
  ${tw`sticky`};
  top: ${(props) => (props.stickToHeader ? props.theme.headerHeight : 0)};
`;

// export const StickyScroll = ({ stickHeight, children }) => {
//   const section = useRef(null);

//   // useEffect(() => {
//   //   const sticky = section.current.offsetTop;
//   //   const scrollCallBack = window.addEventListener("scroll", () => {
//   //     if (window.pageYOffset > sticky) {
//   //       section.current.classList.add("sticky");
//   //       section.current.classList.add("top-0");
//   //     } else {
//   //       section.current.classList.remove("sticky");
//   //       section.current.classList.remove("top-0");
//   //     }
//   //   });
//   //   return () => {
//   //     window.removeEventListener("scroll", scrollCallBack);
//   //   };
//   // }, []);

//   return (
//     <div id="sticky-section" className="sticky top-0" ref={section}>
//       {children}
//     </div>
//   );
// };
