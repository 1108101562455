import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Card = styled.div.attrs({
  className:
    "flex flex-row justify-items-start h-12 m-2 w-screen max-w-full px-2 space-x-2",
})`
  & {
    img {
      ${tw`h-12 w-12`}
    }

    div#card-image {
      ${tw`flex-none`}
    }

    div#card-content {
      ${tw`flex flex-col overflow-hidden`}
    }

    div#card-header {
      ${tw`row-span-1 col-span-5 text-left font-medium text-white truncate`}
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.015em;
      white-space: nowrap;
      overflow: hidden;
    }

    div#card-subtitle {
      ${tw`row-span-1 col-span-5 text-left text-white font-medium flex-grow-0`};
      --text-opacity: 0.6;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.015em;
    }
  }
`;

type Props = {
  // TODO Spotify API returns album despite docs saying it doesn-t
  track: SpotifyApi.TrackObjectSimplified & {
    album?: SpotifyApi.AlbumObjectSimplified;
  };
};

export const TrackCard = ({ track }: Props) => {
  return (
    <Card>
      {track.album && (
        <div id="card-image">
          <img src={track.album.images[0].url} alt="track album cover" />
        </div>
      )}
      <div id="card-content">
        <div id="card-header">{track.name}</div>
        <div id="card-subtitle">{track.artists[0].name}</div>
      </div>
    </Card>
  );
};
